<template>
  <div>

    <div class="blog-detail-wrapper">
      <b-row>
        <b-col
          xl="2"
          lg="0"
        />

        <b-col
          xl="8"
          lg="12"
        >
          <b-card
            :img-src="blog.image"
            img-top
            :img-alt="blog.title"
          >

            <div class="d-flex align-items-center text-body mb-1">
              <feather-icon
                icon="CalendarIcon"
                class="mr-50"
              />
              <span class="font-weight-bold">{{ blog.date | formatDate }}</span>
            </div>

            <!-- eslint-disable vue/no-v-html -->

            <h1 class="mb-2 mt-2">{{ blog.title }}</h1>
            <hr class="my-2">
            <div
              class="blog-content"
              v-html="blog.text"
            />

            <!-- eslint-enable -->


          </b-card>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTab, BTabs, BCardText, BCardTitle, BButton, BForm, BFormInput, BFormGroup, BImg, BModal, VBToggle, BSpinner, BFormSelect
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import NavBar from '@/layouts/components/Navbar.vue'
import Ripple from 'vue-ripple-directive'
// import store from '@/store'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BRow, BCol, BCard, BTab, BTabs, BCardText, BCardTitle, BButton, BForm, BFormInput, BFormGroup, BImg, BModal, AppTimeline, AppTimelineItem, BSpinner, BFormSelect
    // , NavBar
    // , store
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
        blog: {}
      }
  },

  mounted() {
    if (this.$route.params.id) {
    this.loadOneNews(this.$route.params.id)
    }

  },
  methods: {
  loadOneNews(newsId) {
      useJwt.getOneNews(newsId, {
      })
        .then(response => {
          const resData = response.data
          this.blog = resData
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error getting news',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.data.email,
            },
          })
        })
        .finally(()=>{
        })
    },
  },

  created() {

  },

  destroyed() {

  },

}
</script>

<style lang="scss">
    .blog-content img {
        max-width: 100%;
        height: auto;
    }
</style>
